<template>
  <div>
    <Vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </Vertical>
    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Vertical from '@/views/layouts/Vertical.vue'
import Horizontal from '@/views/layouts/Horizontal.vue'

export default {
  name: 'Layout',

  components: {
    Vertical,
    Horizontal,
  },

  computed: {
    ...mapGetters(['layoutType']),
  },
}
</script>
