<template>
  <b-modal
    id="modalRegistroPonto"
    title="Registrar Ponto"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="registroPonto">
      <div class="row">
        <div class="col-sm-12">
          <p><strong>Nome:</strong> {{ user.name }}</p>
        </div>
        <div class="col-sm-12">
          <p><strong>E-mail:</strong> {{ user.email }}</p>
        </div>
        <div class="col-sm-12">
          <p class="mb-0"><strong>Celular:</strong> {{ user.phone }}</p>
        </div>
      </div>
      <hr />
      <h5>Registrar Ponto</h5>
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label>Tipo de Registro: <span class="text-danger">*</span></label>
          <select
            class="form-control"
            :class="{
              'is-invalid': $v.tipo_registro.$error,
            }"
            v-model="$v.tipo_registro.$model"
          >
            <option value="">Selecione o tipo de registro</option>
            <option value="1">Entrada</option>
            <option value="0">Saída</option>
          </select>
          <span v-if="!$v.tipo_registro.required" class="invalid-feedback">
            O campo é obrigatório
          </span>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          title="Atualizar"
          :disabled="$v.$invalid || disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>Registrar Ponto</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'RegistroPontoModal',

  data() {
    return {
      userId: this.$store.getters.user.id,
      user: {
        name: '',
        email: '',
        phone: '',
      },
      tipo_registro: '',
      disableButton: false,
    }
  },

  methods: {
    async getUser() {
      try {
        this.user = await this.$store.dispatch('getUser', this.userId)
        this.$bvModal.show('modalRegistroPonto')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async registroPonto() {
      if (!this.$v.$invalid) {
        this.disableButton = true
        try {
          const response = await this.$store.dispatch('registroPonto', {
            userId: this.userId,
            tipo_registro: this.tipo_registro,
          })
          this.disableButton = false
          this.$root.$bvToast.toast(response.message, {
            title: 'Sucesso!',
            variant: 'success',
            solid: true,
          })
          this.$emit('closeModal', false)
        } catch (error) {
          this.disableButton = false
          if (error && error.message) {
            this.$root.$bvToast.toast(error.message, {
              title: 'Atenção!',
              variant: 'warning',
              solid: true,
            })
          } else {
            this.$root.$bvToast.toast(
              'Não foi possível se conectar ao servidor.',
              {
                title: 'Erro!',
                variant: 'danger',
                solid: true,
              },
            )
          }
        }
      }
    },
  },

  validations: {
    tipo_registro: {
      required,
    },
  },

  mounted() {
    this.getUser()
  },
}
</script>
