<template>
  <div>
    <div id="preloader">
      <div id="status">
        <div class="spinner">
          <i class="ri-loader-line spin-icon" />
        </div>
      </div>
    </div>
    <div id="layout-wrapper">
      <TopbarHorizontal :type="topbarMode" :width="layoutWidth" />
      <NavbarHorizontal />
      <div class="main-content">
        <div class="page-content">
          <div class="container-fluid">
            <slot />
          </div>
        </div>
        <Footer />
      </div>
    </div>
    <SidebarRight />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TopbarHorizontal from '@/components/commons/TopbarHorizontal.vue'
import NavbarHorizontal from '@/components/commons/NavbarHorizontal.vue'
import Footer from '@/components/commons/Footer.vue'
import SidebarRight from '@/components/commons/SidebarRight.vue'

export default {
  name: 'Horizontal',

  components: {
    TopbarHorizontal,
    NavbarHorizontal,
    Footer,
    SidebarRight,
  },

  computed: {
    ...mapGetters(['layoutWidth', 'topbarMode']),
  },

  methods: {
    toggleRightSidebar() {
      document.body.classList.toggle('right-bar-enabled')
    },
    hideRightSidebar() {
      document.body.classList.remove('right-bar-enabled')
    },
  },

  mounted() {
    document.body.classList.remove('auth-body-bg')
    if (this.loader === true) {
      document.getElementById('preloader').style.display = 'block'
      document.getElementById('status').style.display = 'block'

      setTimeout(() => {
        document.getElementById('preloader').style.display = 'none'
        document.getElementById('status').style.display = 'none'
      }, 2500)
    } else {
      document.getElementById('preloader').style.display = 'none'
      document.getElementById('status').style.display = 'none'
    }
  },

  created: () => {
    document.body.setAttribute('data-layout', 'horizontal')
    document.body.setAttribute('data-topbar', 'dark')
    document.body.removeAttribute('data-sidebar', 'dark')
    document.body.removeAttribute('data-layout-size', 'boxed')
  },
}
</script>
